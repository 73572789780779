import axios from "axios";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Banner from "./assets/website/orange-pattern.jpg";
import "./Subscribe.css";

const BannerImg = {
  backgroundImage: `url(${Banner})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "100%",
  width: "100%",
};

const Subscribe = () => {
  const [email, setEmail] = useState(""); // State for the email input

  const handleSubscribe = async () => {
    if (!email) {
      toast.error("يرجى إدخال بريد إلكتروني صالح.", { position: "top-right" });
      return;
    }

    try {
      await axios.post("https://api.echfak.store/api/subscribers", { email });
      toast.success("تم الاشتراك بنجاح!", { position: "top-right" });
      setEmail(""); // Clear the input field
    } catch (error) {
      toast.error("حدث خطأ أثناء الاشتراك. حاول مرة أخرى.", { position: "top-right" });
      console.error(error);
    }
  };

  return (
    <div data-aos="zoom-in" className="subscribe-wrapper" style={BannerImg}>
      <ToastContainer />
      <div dir="rtl" className="subscribe-container">
        <div className="subscribe-content">
          <h1 className="subscribe-title">احصل على إشعارات حول المنتجات الجديدة</h1>
          <div className="subscribe-input-container" data-aos="fade-up">
            <input
              type="email"
              placeholder="أدخل بريدك الإلكتروني"
              className="subscribe-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="subscribe-button" onClick={handleSubscribe}>
              حفظ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
