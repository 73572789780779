import axios from "axios";
import React, { useRef, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import "./style.css";

export default function CategoryList() {
    const { categories } = useOutletContext();
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const itemsPerPage = 5;
    const topRef = useRef();

    const deleteCategory = async (id) => {
        await axios.delete(`https://api.echfak.store/api/categories/${id}`)
            .then(({ data }) => {
                // console.log(data.message);
                setShowConfirmationModal(false);
                window.location.reload();
            })
            .catch(({ response: { data } }) => {
                // console.log(data.message);
                setShowConfirmationModal(false);
            });
    };

    const confirmDelete = (id, title) => {
        setCategoryToDelete({ id, title });
        setShowConfirmationModal(true);
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
    };

    // Filter categories based on search query
    const filteredCategories = categories.filter(category => 
        category.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        category.description.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Pagination logic
    const totalPages = Math.ceil(filteredCategories.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedCategories = filteredCategories.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };
    const getPagination = () => {
        const pagination = [];
    
        if (currentPage > 3) {
            pagination.push("...");
        }
    
        if (currentPage > 2) {
            pagination.push(currentPage - 1);
        }
    
        pagination.push(currentPage);
    
        if (currentPage < totalPages - 1) {
            pagination.push(currentPage + 1);
        }
    
        if (currentPage < totalPages - 2) {
            pagination.push("...");
        }
    
        return pagination;
    };
    

    return (
        <div className="custom-container" ref={topRef}>
            <div className="custom-row">
                <div className="custom-col-12 over">
                    <Link className="custom-btn custom-btn-primary custom-btn-float" to={"/category/create"}>
                        Create
                    </Link>
                    <input
                        type="text"
                        className="custom-form-control custom-mb-2"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ maxWidth: "300px" }}
                    />
                    <div className="custom-col-12">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Image</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedCategories.length > 0 ? (
                                    paginatedCategories.map((row, key) => (
                                        <tr key={key}>
                                            <td style={{ maxWidth: "120px" }}>{row.title}</td>
                                            <td style={{ maxWidth: "120px" }}>{row.description}</td>
                                            <td>
                                                <img
                                                    width="100px"
                                                    src={`https://api.echfak.store/storage/category/image/${row.image}`}
                                                    alt="Category"
                                                />
                                            </td>
                                            <td>
                                                <Link
                                                    className="custom-btn custom-btn-success"
                                                    to={`/category/edit/${row.id}`}
                                                    style={{ marginRight: "10px" }}
                                                >
                                                    Edit
                                                </Link>
                                                <button
                                                    className="custom-btn custom-btn-danger"
                                                    onClick={() => confirmDelete(row.id, row.title)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No categories found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination">
    <button
        className="custom-btn"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
    >
        Previous
    </button>
    {getPagination().map((page, index) =>
        typeof page === "number" ? (
            <button
                key={index}
                className={`custom-btn ${
                    currentPage === page ? "custom-btn-primary" : "custom-btn-secondary"
                }`}
                onClick={() => handlePageChange(page)}
            >
                {page}
            </button>
        ) : (
            <span key={index} className="pagination-ellipsis">
                ...
            </span>
        )
    )}
    <button
        className="custom-btn"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
    >
        Next
    </button>
</div>

                    </div>
                </div>
            </div>

            {/* Confirmation Modal */}
            {showConfirmationModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Are you sure?</h2>
                        <p>Do you want to delete the category "{categoryToDelete.title}"?</p>
                        <button
                            className="custom-btn custom-btn-danger"
                            onClick={() => deleteCategory(categoryToDelete.id)}
                        >
                            OK
                        </button>
                        <button
                            className="custom-btn custom-btn-secondary"
                            onClick={handleCancelDelete}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
