import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../common/header/Header';
import Footer from '../common/footer/Footer';
import axios from 'axios';
import './Layout.css';
import { Snackbar, Alert } from '@mui/material';
import API_BASE_URL from '../config';

const Layout = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [personals, setPersonals] = useState([]);
  const [cities, setCities] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [promotions, setPromotion] = useState([]);
  const [subscribe, setSubscribe] = useState([]);
  const [banners, setBanners] = useState([]);
  const [comments, setComments] = useState([]);
  const [CartItem, setCartItem] = useState(() => {
    const storedCart = JSON.parse(localStorage.getItem("cartItems")) || [];
    return storedCart;
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  // Fetch all data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productsRes, categoriesRes, personalsRes, citiesRes,slidersRes,promotionRes,subscribeRes,bannersRes,commentsRes] = await Promise.all([
          axios.get(`https://api.echfak.store/api/products`),
          axios.get(`https://api.echfak.store/api/categories`),
          axios.get(`https://api.echfak.store/api/personals`),
          axios.get(`https://api.echfak.store/api/villes`),
          axios.get(`https://api.echfak.store/api/sliders`),
          axios.get(`https://api.echfak.store/api/promotions`),
          axios.get(`https://api.echfak.store/api/subscribers`),
          axios.get(`https://api.echfak.store/api/banners`),
          axios.get(`https://api.echfak.store/api/comments`),
        ]);

        setProducts(productsRes.data);
        setCategories(categoriesRes.data);
        setPersonals(personalsRes.data);
        setCities(citiesRes.data);
        setSliders(slidersRes.data);
        setPromotion(promotionRes.data);
        setSubscribe(subscribeRes.data);
        setBanners(bannersRes.data);
        setComments(commentsRes.data);

        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Stop loading even if there's an error
      }
    };

    fetchData();
  }, []);

  // Sync CartItem with localStorage
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(CartItem));
  }, [CartItem]);

  // Add to Cart with Toast Notifications
  // const addToCart = (product) => {
  //   const productExists = CartItem.find((item) => item.id === product.id);
  //   if (productExists) {
  //     setCartItem(
  //       CartItem.map((item) =>
  //         item.id === product.id ? { ...item, qty: item.qty + 1 } : item
  //       )
  //     );
  //     setSnackbar({ open: true, message: 'تمت زيادة الكمية في سلة المبيعات', severity: 'success' });
  //   } else {
  //     setCartItem([...CartItem, { ...product, qty: 1 }]);
  //     setSnackbar({ open: true, message: 'لقد تمت باضافة المنتج الى سلة المبيعات', severity: 'success' });
  //   }
  // };
  // Function for adding to cart from Products
const addToCart = (product) => {
  const productExists = CartItem.find((item) => item.id === product.id);
  if (productExists) {
    setSnackbar({ open: true, message: 'المنتج موجود لديك في سلة المبيعات', severity: 'info' });
  } else {
    setCartItem([...CartItem, { ...product, qty: 1 }]);
    setSnackbar({ open: true, message: 'لقد تمت اضافة المنتج الى سلة المبيعات', severity: 'success' });
  }
};

// Function for incrementing quantity from Cart
const incrementQty = (productId) => {
  const updatedCart = CartItem.map((item) =>
    item.id === productId ? { ...item, qty: item.qty + 1 } : item
  );
  setCartItem(updatedCart);
};

  

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: '' });
  };
  // Decrease Quantity
  const decreaseQty = (product) => {
    const productExit = CartItem.find((item) => item.id === product.id);
    if (productExit.qty === 1) {
      setCartItem(CartItem.filter((item) => item.id !== product.id));
    } else {
      setCartItem(
        CartItem.map((item) =>
          item.id === product.id
            ? { ...productExit, qty: productExit.qty - 1 }
            : item
        )
      );
    }
  };

  return (
    <div>
      {loading && (
        <div className="loading-screen">
          <div className="spinner"></div>
          <h2>جاري التحميل... الرجاء الإنتظار</h2>
        </div>
      )}
      <div className={loading ? "content blur" : "content"}>
        <Header CartItem={CartItem} personals={personals} />
        <Outlet
          context={{
            addToCart,
            incrementQty,
            CartItem,
            setCartItem,
            decreaseQty,
            products,
            categories,
            personals,
            cities,
            sliders,
            promotions,
            subscribe,
            banners,
            comments,
          }}
        />
        <Footer personals={personals} />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%', direction: 'rtl' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default Layout;
