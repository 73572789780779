import React from "react"
import { useOutletContext } from "react-router-dom"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

const SlideCard = () => {
  const { products } = useOutletContext();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    appendDots: (dots) => {
      return <ul style={{ margin: "0px" }}>{dots}</ul>
    },
  }

  return (
    <>
      <Slider {...settings}>
        {products.map((row, key) => {
          return (
            <>
              <div className='box d_flex top' key={key}>
                <div className='left'>
                  <h1>{row.title}</h1>
                  <p>{row.description}</p>
                  <button className='btn-primary'>Visit Collections</button>
                </div>
                <div className='right'>
                  <img src={`https://api.echfak.store/storage/product/image/${row.image }`} alt='' />
                </div>
              </div>
            </>
          )
        })}
      </Slider>
    </>
  )
}

export default SlideCard
