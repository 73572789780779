import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CreateVille() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const createVille = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("price", price);

    // console.log(formData);
    await axios
      .post("https://api.echfak.store/api/villes", formData)
      .then(({ data }) => {
        // console.log(data.message);
        setShowSuccessModal(true); // Show success modal
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          // console.log(response.data.errors);
        } else {
          // console.log(response.data.message);
        }
      });
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate("/ville/list"); // Redirect after closing modal
  };

  return (
    <div className="custom-container">
      <div className="custom-row custom-justify-center">
        <div className="custom-col-12">
          <div className="custom-card">
            <div className="custom-card-body">
              <h3 className="custom-card-title">Create Form</h3>
              <hr />
              <div className="custom-form-wrapper">
                <form onSubmit={createVille}>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Name</label>
                    <input
                      type="text"
                      className="custom-form-input"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Price</label>
                    <textarea
                      className="custom-form-textarea"
                      rows="3"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="custom-form-group">
                    <button
                      type="submit"
                      className="custom-btn custom-btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Congratulations!</h2>
            <p>The city has been successfully created.</p>
            <button className="custom-btn custom-btn-success" onClick={handleCloseModal}>
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
