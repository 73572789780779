import { createBrowserRouter } from "react-router-dom";
import Cart from "../common/Cart/Cart";
import CreateBanner from "../components/bannerDashboard/create.component";
import EditBanner from "../components/bannerDashboard/edit.component";
import BannerList from "../components/bannerDashboard/list.component";
import CreateCategory from '../components/categories/create.component';
import EditCategory from '../components/categories/edit.component';
import CategoryList from '../components/categories/list.component';
import CreateComment from "../components/commentDashboard/create.component";
import EditComment from "../components/commentDashboard/edit.component";
import CommentList from "../components/commentDashboard/list.component";
import Dashboard from "../components/dashboard/home/Dashboard";
import CreateSlider from "../components/heroDachboard/create.component";
import EditSlider from "../components/heroDachboard/edit.component";
import HeroList from "../components/heroDachboard/list.component";
import LoginAdmin from "../components/loginAdmin/LoginAdmin";
import OrderList from "../components/orders/list.component";
import CreatePersonal from "../components/personals/create.component";
import EditPersonal from "../components/personals/edit.component";
import PersonalList from "../components/personals/list.component";
import CreateProduct from "../components/products/create.component";
import EditProduct from "../components/products/edit.component";
import ProductList from "../components/products/list.component";
import CreatePromotion from "../components/promotionDashboard/create.component";
import EditPromotion from "../components/promotionDashboard/edit.component";
import PromotionList from "../components/promotionDashboard/list.component";
import SubscribersList from "../components/subsribersDashboard/list.component";
import CreateVille from "../components/villes/create.component";
import EditVille from "../components/villes/edit.component";
import VilleList from "../components/villes/list.component";
import Layout from "../layouts/Layout";
import LayoutDashboard from "../layouts/LayoutDashboard";
import Pages from "../pages/Pages";



export const router = createBrowserRouter([
    {
        element : <Layout />,
        children : [
            {
                element : <Pages />,
                path : "/"
            },
            {
                element : <Cart />,
                path : "/cart"
            },
            // {
            //     element : <Dashboard />,
            //     path : "/dashboard/mohammad/X537hdd636jdh/hdhdks8446hdhdjz/hhdeediJ9U393UND3I3HI/UDZDHIDEI98793J3K3HHJdhehuedjhhdeuedueguguideuedueueeddgueiziyzvzvcvycyeygyeggydedgeydgedygedygedgedyedgy/83474844647HHC847484748473838748474747DHDHHEHEHEHEHR84748483837373666262737664646BBCVFRRJFKFKD84747487585858577484847575484747HDGDTEYRKEDKSDKSTSTEEEG"
            // },
            {
                element : <LoginAdmin />,
                path : "/login"
            },
        ]
    },
    {
        element : <LayoutDashboard />,
        children : [
            {
                element : <Dashboard />,
                path : "/dashboard/mohammad/X537hdd636jdh/hdhdks8446hdhdjz/hhdeediJ9U393UND3I3HI/UDZDHIDEI98793J3K3HHJdhehuedjhhdeuedueguguideuedueueeddgueiziyzvzvcvycyeygyeggydedgeydgedygedygedgedyedgy/83474844647HHC847484748473838748474747DHDHHEHEHEHEHR84748483837373666262737664646BBCVFRRJFKFKD84747487585858577484847575484747HDGDTEYRKEDKSDKSTSTEEEG"
            },
            {
                element : <CreateProduct />,
                path : "/product/create"
            },
            {
                element : <EditProduct />,
                path : "/product/edit/:id"
            },
            {
                element : <ProductList />,
                path : "/product/list"
            },
            {
                element : <CreateCategory />,
                path : "/category/create"
            },
            {
                element : <EditCategory />,
                path : "/category/edit/:id"
            },
            {
                element : <CategoryList />,
                path : "/category/list"
            },
            {
                element : <VilleList />,
                path : "/ville/list"
            },
            {
                element : <CreateVille />,
                path : "/ville/create"
            },
            {
                element : <EditVille />,
                path : "/ville/edit/:id"
            },
            {
                element : <PersonalList />,
                path : "/personal/list"
            },
            {
                element : <CreatePersonal />,
                path : "/personal/create"
            },
            {
                element : <EditPersonal />,
                path : "/personal/edit/:id"
            },
            {
                element : <OrderList />,
                path : "/order/list"
            },
            {
                element : <HeroList />,
                path : "/sliders/list"
            },
            {
                element : <CreateSlider />,
                path : "/slider/create"
            },
            {
                element : <EditSlider />,
                path : "/slider/edit/:id"
            },
            {
                element : <PromotionList />,
                path : "/promotions/list"
            },
            {
                element : <CreatePromotion />,
                path : "/promotion/create"
            },
            {
                element : <EditPromotion />,
                path : "/promotion/edit/:id"
            },
            {
                element : <SubscribersList />,
                path : "/subscibers/list"
            },
            {
                element : <BannerList />,
                path : "/banners/list"
            },
            {
                element : <CreateBanner />,
                path : "/banner/create"
            },
            {
                element : <EditBanner />,
                path : "/banner/edit/:id"
            },
            {
                element : <CommentList />,
                path : "/comments/list"
            },
            {
                element : <CreateComment />,
                path : "/comment/create"
            },
            {
                element : <EditComment />,
                path : "/comment/edit/:id"
            },
        ]
    }
])