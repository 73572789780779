import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { utils, writeFile } from "xlsx";
import "./style.css";
import { useOutletContext } from "react-router-dom";

export default function OrderList() {
    const { orders } = useOutletContext();
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dateFilter, setDateFilter] = useState("all");
    const itemsPerPage = 5;
    const topRef = useRef();

    // Filter orders based on search query and date filter
    // console.log("orders :",orders)
    const filteredOrders = orders?.filter(order => {
        const matchesSearch = 
            order.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            order.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
            order.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
            order.phone.includes(searchQuery);

        const matchesDate = (() => {
            if (dateFilter === "today") {
                const today = new Date();
                const orderDate = new Date(order.created_at);
                return (
                    orderDate.getDate() === today.getDate() &&
                    orderDate.getMonth() === today.getMonth() &&
                    orderDate.getFullYear() === today.getFullYear()
                );
            }
            return true; // "all" filter
        })();

        return matchesSearch && matchesDate;
    });

    // Pagination logic
    const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedOrders = filteredOrders.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handleDateFilterChange = (e) => {
        setDateFilter(e.target.value);
        setCurrentPage(1);
    };

    const downloadAsExcel = () => {
        const data = filteredOrders.map(order => ({
            "Customer Name": order.customer_name,
            "Address": order.address,
            "City": order.city,
            "Phone": order.phone,
            "Total Price": order.total_price,
            "Created At": new Date(order.created_at).toLocaleString(),
            "Items": order.items.map(item => `${item.product.title} (Qty: ${item.quantity}, Price: ${item.price})`).join(", ")
        }));

        const worksheet = utils.json_to_sheet(data);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Orders");
        writeFile(workbook, "Orders.xlsx");
    };

    const handlePrintOrders = () => {
        const printWindow = window.open("", "_blank");
        const printContent = `
            <html>
            <head>
                <title>Liste des commandes</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        margin-bottom: 20px;
                    }
                    th, td {
                        border: 1px solid #ddd;
                        padding: 8px;
                        text-align: left;
                    }
                    th {
                        background-color: #f2f2f2;
                    }
                    .total-price {
                        font-weight: bold;
                        text-align: right;
                        margin-top: 10px;
                    }
                </style>
            </head>
            <body>
                ${filteredOrders.map(order => `
                    <div>
                        <p><strong></strong> ${order.customer_name}</p>
                        <p><strong></strong> ${order.phone}</p>
                        <p><strong></strong> ${order.address}</p>
                        <p><strong></strong> ${order.city}</p>
                        <p><strong>Date :</strong> ${new Date(order.created_at).toLocaleString()}</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Produit</th>
                                    <th>Quantité</th>
                                    <th>Prix</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${order.items.map(item => `
                                    <tr>
                                        <td>${item.product.title}</td>
                                        <td>${item.quantity}</td>
                                        <td>${item.price} DH</td>
                                    </tr>
                                `).join("")}
                            </tbody>
                        </table>
                        <p class="total-price"><strong>Prix total:</strong> ${order.total_price} DH</p>
                        <hr/>
                    </div>
                `).join("")}
            </body>
            </html>
        `;
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.print();
    };
    const handlePrintSingleOrder = (order) => {
        const printWindow = window.open("", "_blank");
        const printContent = `
            <html>
            <head>
                <title>Order Details</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        margin-bottom: 20px;
                    }
                    th, td {
                        border: 1px solid #ddd;
                        padding: 8px;
                        text-align: left;
                    }
                    th {
                        background-color: #f2f2f2;
                    }
                    .total-price {
                        font-weight: bold;
                        text-align: right;
                        margin-top: 10px;
                    }
                </style>
            </head>
            <body>
                <div>
                    <p><strong></strong> ${order.customer_name}</p>
                    <p><strong></strong> ${order.phone}</p>
                    <p><strong></strong> ${order.address}</p>
                    <p><strong></strong> ${order.city}</p>
                    <p><strong>Date :</strong> ${new Date(order.created_at).toLocaleString()}</p>
                    <table>
                        <thead>
                            <tr>
                                <th>Produit</th>
                                <th>Quantité</th>
                                <th>Prix</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${order.items.map(item => `
                                <tr>
                                    <td>${item.product.title}</td>
                                    <td>${item.quantity}</td>
                                    <td>${item.price} DH</td>
                                </tr>
                            `).join("")}
                        </tbody>
                    </table>
                    <p class="total-price"><strong>Prix total :</strong> ${order.total_price} DH</p>
                </div>
            </body>
            </html>
        `;
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.print();
    };

    const getPagination = () => {
        const pages = [];
    
        if (totalPages <= 5) {
            // Show all pages if total pages are 5 or less
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            // Show ellipses and relevant pages
            if (currentPage > 2) {
                pages.push(1);
            }
            if (currentPage > 3) {
                pages.push("...");
            }
            if (currentPage > 1) {
                pages.push(currentPage - 1);
            }
            pages.push(currentPage);
            if (currentPage < totalPages) {
                pages.push(currentPage + 1);
            }
            if (currentPage < totalPages - 2) {
                pages.push("...");
            }
            if (currentPage < totalPages - 1) {
                pages.push(totalPages);
            }
        }
    
        return pages;
    };
    
    

    return (
        <div className="custom-container" ref={topRef}>
            <div className="custom-row">
                <div className="custom-col-12 over">
                    <div className="filter-container">
                        <input
                            type="text"
                            className="custom-form-control custom-mb-2"
                            placeholder="Search by customer name, address, city, or phone..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            style={{ maxWidth: "300px" }}
                        />
                        <select
                            className="custom-form-control custom-mb-2"
                            style={{ maxWidth: "200px" }}
                            value={dateFilter}
                            onChange={handleDateFilterChange}
                        >
                            <option value="all">All</option>
                            <option value="today">Today</option>
                        </select>
                        <button
                            className="custom-btn custom-mb-2"
                            onClick={downloadAsExcel}
                            style={{ marginLeft: "10px" }}
                        >
                            Download as Excel
                        </button>
                        <button
                        className="custom-btn custom-mb-2"
                        onClick={handlePrintOrders}
                        style={{ marginLeft: "10px" }}
                    >
                        Print Orders
                    </button>
                    </div>
                    <div className="custom-col-12">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">City</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Total Price</th>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Items</th>
                                </tr>
                            </thead>
                            <tbody>
    {paginatedOrders.length > 0 ? (
        paginatedOrders.map((order, key) => (
            <tr key={key}>
                <td>{order.customer_name}</td>
                <td>{order.address}</td>
                <td>{order.city}</td>
                <td>{order.phone}</td>
                <td>{order.total_price}</td>
                <td>{new Date(order.created_at).toLocaleString()}</td>
                <td>
                    <ul>
                        {order.items.map((item, index) => (
                            <li key={index}>
                                <strong>Product:</strong> {item.product.title} | 
                                <strong> Quantity:</strong> {item.quantity} | 
                                <strong> Price:</strong> {item.price}
                            </li>
                        ))}
                    </ul>
                </td>
                <td>
                    {/* Add the print icon */}
                    <button
                        className="custom-btn custom-btn-secondary"
                        onClick={() => handlePrintSingleOrder(order)}
                        style={{ backgroundColor : "black", border: "none", cursor: "pointer" }}
                        title="Print this order"
                    >
                        <i className="fa-solid fa-print"></i>
                    </button>
                </td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan="8">No orders found.</td>
        </tr>
    )}
</tbody>
                        </table>
                        <div className="pagination">
    <button
        className="custom-btn"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
    >
        Previous
    </button>
    {getPagination().map((page, index) =>
        typeof page === "number" ? (
            <button
                key={index}
                className={`custom-btn ${
                    currentPage === page ? "custom-btn-primary" : "custom-btn-secondary"
                }`}
                onClick={() => handlePageChange(page)}
            >
                {page}
            </button>
        ) : (
            <span key={index} className="pagination-ellipsis">
                ...
            </span>
        )
    )}
    <button
        className="custom-btn"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
    >
        Next
    </button>
</div>

                    </div>
                </div>
            </div>
        </div>
    );
}
