import * as React from 'react'
// import "bootstrap/dist/css/bootstrap.css"
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import "./App.css"


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
