import { useRef } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar({CartItem , personals}) {

  window.addEventListener("scroll", function () {
    const search = document.querySelector(".search")
    // search.classList.toggle("active", window.scrollY > 100)
  })

	const navRef = useRef();

	// const showNavbar = () => {
	// 	navRef.current.classList.toggle(
	// 		"responsive_nav"
	// 	);
	// };
  // const closeNavbar = () => {
  //   navRef.current.classList.remove("responsive_nav");
  // };

	return (
    <div className="navbar_background">

    <section className='search navbar'>
        <div className='container c_flex'>
          <Link to={"/"}>
          <div className='logo width'>
            {/* <img src="" alt='Echfak' /> */}
            <img src={`https://api.echfak.store/storage/personal/logo/${personals[0]?.logo}`} alt={personals[0]?.logo} />
            {/* <img src={logo} alt={personals[0]?.logo} /> */}
          </div>
          </Link>

          {/* <div className='search-box f_flex'>
            <i className='fa fa-search'></i>
            <input type='text' placeholder='Search and hit enter...' />
            <span>All Category</span>
          </div> */}
          <header>
			<nav ref={navRef}>
				{/* <Link to={"/"} >Home</Link> */}
				{/* <Link to={"/contact"} >Contact</Link> */}
				{/* <Link to={"/about"} >About</Link> */}
				{/* <button
					className="nav-btn nav-close-btn"
					>
					<i className="fa fa-times"></i>
				</button> */}
			</nav>
			{/* <button
				className="nav-btn"
				>
				<i className='fas fa-bars'></i>
			</button> */}
		</header>
          <div className='icon f_flex width'>
            <Link to='/login'>
            <i className='fa fa-user icon-circle'></i>
            </Link>
            <div className='cart'>
              <Link to='/cart'>
                <i className='fa fa-shopping-bag icon-circle'></i>
                <span>{CartItem.length === 0 ? 0 : CartItem.length}</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
	);
}

export default Navbar;