import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CreatePromotion() {
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false); // For success modal

    const changeHandler = (e) => {
        setImage(e.target.files[0]);
        // console.log(e.target.files[0]);
    };

    const createPromotion = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("image", image);

        // console.log(formData);
        await axios
            .post("https://api.echfak.store/api/promotions", formData)
            .then(({ data }) => {
                // console.log(data.message);
                setShowSuccessModal(true); // Show success modal
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    // console.log(response.data.errors);
                } else {
                    // console.log(response.data.message);
                }
            });
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        navigate("/promotions/list"); // Redirect after closing modal
    };

    return (
        <div className="custom-container">
            <div className="custom-row custom-justify-center">
                <div className="custom-col-12">
                    <div className="custom-card">
                        <div className="custom-card-body">
                            <h3 className="custom-card-title">Create Promotion</h3>
                            <hr />
                            <div className="custom-form-wrapper">
                                <form onSubmit={createPromotion}>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Title</label>
                                        <input
                                            type="text"
                                            className="custom-form-input"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Description</label>
                                        <textarea
                                            className="custom-form-textarea"
                                            rows="3"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        ></textarea>
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Image (500 x 500)</label>
                                        <input
                                            type="file"
                                            className="custom-form-input"
                                            onChange={changeHandler}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <button type="submit" className="custom-btn custom-btn-primary">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Congratulations!</h2>
                        <p>The promotion has been successfully created.</p>
                        <button className="custom-btn custom-btn-success" onClick={handleCloseModal}>
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
