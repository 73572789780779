import axios from "axios";
import React, { useRef, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import "./style.css";

export default function VilleList() {
    const { villes } = useOutletContext();
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false); // For confirmation modal
    const [cityToDelete, setCityToDelete] = useState(null); // Store the city to delete
    const itemsPerPage = 5;
    const topRef = useRef();

    const deleteVille = async (id) => {
        await axios
            .delete(`https://api.echfak.store/api/villes/${id}`)
            .then(({ data }) => {
                // console.log(data.message);
                setShowConfirmationModal(false); // Hide confirmation modal
                window.location.reload(); 
            })
            .catch(({ response: { data } }) => {
                // console.log(data.message);
                setShowConfirmationModal(false); // Hide confirmation modal if error occurs
            });
    };

    const confirmDelete = (id, name) => {
        setCityToDelete({ id, name }); // Store the city details to delete
        setShowConfirmationModal(true); // Show confirmation modal
    };

    // Filter villes based on search query
    const filteredVilles = villes.filter((ville) =>
        ville.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Pagination logic
    const totalPages = Math.ceil(filteredVilles.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedVilles = filteredVilles.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false); // Hide the confirmation modal
    };

    return (
        <div className="custom-container" ref={topRef}>
            <div className="custom-row">
                <div className="custom-col-12 over">
                    <Link
                        className="custom-btn custom-btn-primary custom-mb-2 custom-float-end"
                        to={"/ville/create"}
                    >
                        Create
                    </Link>
                    <input
                        type="text"
                        className="custom-form-control custom-mb-2"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ maxWidth: "300px" }}
                    />
                    <div className="custom-col-12">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedVilles.length > 0 ? (
                                    paginatedVilles.map((row, key) => (
                                        <tr key={key}>
                                            <td style={{ maxWidth: "120px" }}>{row.name}</td>
                                            <td style={{ maxWidth: "120px" }}>{row.price}</td>
                                            <td>
                                                <Link
                                                    className="custom-btn custom-btn-success"
                                                    to={`/ville/edit/${row.id}`}
                                                    style={{ marginRight: "10px" }}
                                                >
                                                    Edit
                                                </Link>
                                                <button
                                                    className="custom-btn custom-btn-danger"
                                                    onClick={() => confirmDelete(row.id, row.name)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3">No villes found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                className="custom-btn"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index}
                                    className={`custom-btn ${
                                        currentPage === index + 1
                                            ? "custom-btn-primary"
                                            : "custom-btn-secondary"
                                    }`}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button
                                className="custom-btn"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirmation Modal */}
            {showConfirmationModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Are you sure?</h2>
                        <p>Do you want to delete the city "{cityToDelete.name}"?</p>
                        <button
                            className="custom-btn custom-btn-danger"
                            onClick={() => deleteVille(cityToDelete.id)}
                        >
                            OK
                        </button>
                        <button
                            className="custom-btn custom-btn-secondary"
                            onClick={handleCancelDelete}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
