import React from "react";
import Navbar from "../navbar/NavbarDashboard";
import { useOutletContext, useNavigate } from "react-router-dom";
import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const {
    products,
    categories,
    personals,
    villes,
    orders,
    sliders,
    promotions,
    subscribers,
    banners,
    comments,
  } = useOutletContext();

  const stats = [
    { name: "Products", count: products?.length || 0, icon: "📦", route: "/product/list" },
    { name: "Categories", count: categories?.length || 0, icon: "📁", route: "/category/list" },
    { name: "Personals", count: personals?.length || 0, icon: "👤", route: "/personal/list" },
    { name: "Cities", count: villes?.length || 0, icon: "🏙️", route: "/ville/list" },
    { name: "Orders", count: orders?.length || 0, icon: "🛒", route: "/order/list" },
    { name: "Sliders", count: sliders?.length || 0, icon: "🎨", route: "/sliders/list" },
    { name: "Promotions", count: promotions?.length || 0, icon: "💸", route: "/promotions/list" },
    { name: "Subscribers", count: subscribers?.length || 0, icon: "✉️", route: "/subscibers/list" },
    { name: "Banners", count: banners?.length || 0, icon: "🖼️", route: "/banners/list" },
    { name: "Comments", count: comments?.length || 0, icon: "💬", route: "/comments/list" },
  ];

  const handleCardClick = (route) => {
    navigate(route);
  };

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-title">Dashboard Overview</h1>
      <div className="stats-grid">
        {stats.map((stat, index) => (
          <div
            className="stat-card"
            key={index}
            onClick={() => handleCardClick(stat.route)}
            style={{ cursor: "pointer" }}
          >
            <div className="stat-icon">{stat.icon}</div>
            <div className="stat-info">
              <h2>{stat.count}</h2>
              <p>{stat.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
