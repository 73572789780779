import axios from "axios";
import React, { useRef, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import "./style.css";

export default function ProductList() {
    const { products } = useOutletContext();
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const itemsPerPage = 10;
    const topRef = useRef();

    const deleteProduct = async (id) => {
        await axios
            .delete(`https://api.echfak.store/api/api/products/${id}`)
            .then(({ data }) => {
                // console.log(data.message);
                setShowConfirmationModal(false);
                window.location.reload();
            })
            .catch(({ response: { data } }) => {
                // console.log(data.message);
                setShowConfirmationModal(false);
            });
    };

    const confirmDelete = (id, title) => {
        setProductToDelete({ id, title });
        setShowConfirmationModal(true);
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
    };

    const filteredProducts = products.filter((product) =>
        product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.description.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedProducts = filteredProducts.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const getPagination = () => {
        const pagination = [];
      
        if (currentPage > 3) {
          pagination.push("...");
        }
      
        if (currentPage > 2) {
          pagination.push(currentPage - 1);
        }
      
        pagination.push(currentPage);
      
        if (currentPage < totalPages - 1) {
          pagination.push(currentPage + 1);
        }
      
        if (currentPage < totalPages - 2) {
          pagination.push("...");
        }
      
        return pagination;
      };
      

    return (
        <div className="custom-container" ref={topRef}>
            <div className="custom-row">
                <div className="custom-col-12 over">
                    <Link className="custom-btn custom-btn-primary custom-mb-2 custom-float-end" to={"/product/create"}>
                        Create
                    </Link>
                    <input
                        type="text"
                        className="custom-form-control custom-mb-2"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ maxWidth: "300px" }}
                    />
                    <div className="custom-col-12">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th scope="col">Title</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Size</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Colors</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Discount</th>
                                    <th scope="col">Stars</th>
                                    <th scope="col">Top Products</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedProducts.length > 0 ? (
                                    paginatedProducts.map((row, key) => (
                                        <tr key={key}>
                                            <td style={{ maxWidth: "120px" }}>{row.title}</td>
                                            <td style={{ maxWidth: "120px" }}>{row.description}</td>
                                            <td>{row.size}</td>
                                            <td>{row.price}</td>
                                            <td>{row.colors}</td>
                                            <td>{row.category ? row.category.title : "No Category"}</td>
                                            <td>{row.discount}%</td>
                                            <td>{row.stars} ⭐</td>
                                            <td>{row.isValid === "yes" ? "Valid" : "Invalid"}</td>
                                            <td>
                                                <img
                                                    width="100px"
                                                    src={`https://api.echfak.store/storage/product/image/${row.image}`}
                                                    alt="Product"
                                                />
                                            </td>
                                            <td>
                                                <Link
                                                    className="custom-btn custom-btn-success"
                                                    to={`/product/edit/${row.id}`}
                                                    style={{ marginRight: "10px" }}
                                                >
                                                    Edit
                                                </Link>
                                                <button
                                                    className="custom-btn custom-btn-danger"
                                                    onClick={() => confirmDelete(row.id, row.title)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="11">No products found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                className="custom-btn"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            {getPagination().map((page, index) =>
                                typeof page === "number" ? (
                                    <button
                                        key={index}
                                        className={`custom-btn ${
                                            currentPage === page
                                                ? "custom-btn-primary"
                                                : "custom-btn-secondary"
                                        }`}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </button>
                                ) : (
                                    <span key={index} className="pagination-ellipsis">
                                        {page}
                                    </span>
                                )
                            )}
                            <button
                                className="custom-btn"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {showConfirmationModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Are you sure?</h2>
                        <p>Do you want to delete the product "{productToDelete.title}"?</p>
                        <button
                            className="custom-btn custom-btn-danger"
                            onClick={() => deleteProduct(productToDelete.id)}
                        >
                            OK
                        </button>
                        <button
                            className="custom-btn custom-btn-secondary"
                            onClick={handleCancelDelete}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
