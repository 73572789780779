import React, { useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import "./Products.css";

const Products = () => {
  const { products, addToCart } = useOutletContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const productsPerPage = 20;
  const topRef = useRef();

  const filteredProducts = products.filter(
    (product) =>
      product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const startIndex = (currentPage - 1) * productsPerPage;
  const currentProducts = filteredProducts.slice(
    startIndex,
    startIndex + productsPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const getPagination = () => {
    const pagination = [];
  
    if (currentPage > 3) {
      pagination.push("...");
    }
  
    if (currentPage > 2) {
      pagination.push(currentPage - 1);
    }
  
    pagination.push(currentPage);
  
    if (currentPage < totalPages - 1) {
      pagination.push(currentPage + 1);
    }
  
    if (currentPage < totalPages - 2) {
      pagination.push("...");
    }
  
    return pagination;
  };
  

  return (
    <div className="top-products-container">
      <div ref={topRef}></div>

      <div className="top-products-header">
        <p className="header-subtitle" data-aos="fade-up">
          جميع المنتجات
        </p>
        <h1 className="header-title" data-aos="fade-up">
          جميع منتجاتنا
        </h1>
        <p className="header-description" data-aos="fade-up">
          اكتشف جميع منتجات متجرنا
        </p>
      </div>

      <div className="search-container">
        <i
          className="fa-solid fa-magnifying-glass search-icon"
          onClick={() => setIsSearchVisible(!isSearchVisible)}
        ></i>
        {isSearchVisible && (
          <input
            type="text"
            className="search-input"
            placeholder="Search products..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        )}
      </div>

      <div className="top-products-grid">
        {currentProducts.map((data, key) => (
          <div key={key} className="product-card" data-aos="zoom-in">
            <div className="discount-badge">-{data.discount}%</div>

            <div className="product-image">
              <img
                src={`https://api.echfak.store/storage/product/image/${data.image}`}
                alt={data.title}
                className="product-img"
              />
            </div>
            <div dir="rtl" className="product-details">
              <div className="product-stars">
                {[...Array(data.stars)].map((_, i) => (
                  <i key={i} className="fa-solid fa-star star-icon"></i>
                ))}
              </div>
              <h1 className="product-title">{data.title}</h1>
              <p className="product-description">{data.description}</p>
              <h1 dir="rtl" className="top-product-title" style={{ color: "#fbbf24" }}>
                {data.price} درهم
              </h1>
              <button className="product-button" onClick={() => addToCart(data)}>
                أضف إلى السلة
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        {getPagination().map((page, index) =>
          typeof page === "number" ? (
            <button
              key={index}
              className={`pagination-page ${currentPage === page ? "active" : ""}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ) : (
            <span key={index} className="pagination-ellipsis">
              ...
            </span>
          )
        )}
        <button
          className="pagination-button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <i className="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default Products;
