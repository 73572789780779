import React from "react";
import { useOutletContext } from "react-router-dom";
import "./Banner.css";

const Banner = () => {
  const { promotions } = useOutletContext();
  // console.log("promoooo : ",promotions)
  return (
    <div dir="rtl" className="banner-wrapper">
          <div className="banner-container">
            <div className="banner-grid">
              <div data-aos="zoom-in" className="banner-image">
                <img src={`https://api.echfak.store/storage/promotion/image/${promotions[0]?.image}`} alt={promotions[0]?.title} />
              </div>

              {/* Text details section */}
              <div className="banner-details">
                <h1 dir="rtl" data-aos="fade-up" className="banner-title">
                {promotions[0]?.title}
                </h1>
                <p dir="rtl" data-aos="fade-up" className="banner-description">
                {promotions[0]?.description}
                </p>
                <div className="banner-features">
                  <div data-aos="fade-up" className="banner-feature">
                    <i className="fa-solid fa-shield icon-feature icon_1"></i>
                    <p>منتجات ذات جودة عالية</p>
                  </div>
                  <div data-aos="fade-up" className="banner-feature">
                    <i className="fa-solid fa-truck-fast icon-feature icon_2"></i>
                    <p>توصيل سريع</p>
                  </div>
                  <div data-aos="fade-up" className="banner-feature">
                    <i className="fa-solid fa-money-check-dollar icon-feature icon_3"></i>
                    <p>الدفع عند الاستلام</p>
                  </div>
                  <div data-aos="fade-up" className="banner-feature">
                    <i className="fa-solid fa-tags icon-feature icon_4"></i>
                    <p>احصل على العروض</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  );
};

export default Banner;
