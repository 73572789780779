import React from "react";
import "./style.css";

const FooterLinks = [
  { title: "Home", link: "/#" },
  { title: "About", link: "/#about" },
  { title: "Contact", link: "/#contact" },
  { title: "Blog", link: "/#blog" },
];

const Footer = ({personals}) => {
  return (
    <div className="for_margin">
      <div className="footer-container">
        <div className="footer-content">
          <div data-aos="zoom-in" className="footer-grid">
            {/* Company Details */}
            <div className="footer-company">
                <img src={`https://api.echfak.store/storage/personal/logo/${personals[0]?.logo}`} alt="Shopsy Logo" className="footer-logo" />
              <p dir="rtl" className="footer-description">
                {personals[0]?.description_website}
              </p>
              <div className="footer-social-contact">
                <div className="footer-social-links">
                  <a href={personals[0]?.instagram} className="social-icon" target="_blank" >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a href={personals[0]?.facebook}  className="social-icon" target="_blank" >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                  <a href={personals[0]?.tiktok} className="social-icon" target="_blank" >
                    <i className="fa-brands fa-tiktok"></i>
                  </a>
                </div>
                <div className="footer-contact">
                  <div className="contact-item">
                    <i className="fa-solid fa-mobile-screen-button icon_phone"></i>
                    <p>{personals[0]?.phone}</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
