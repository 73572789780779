import axios from "axios";
import React, { useRef, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import "./style.css";

export default function PersonalList() {
    const { personals } = useOutletContext();
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false); // For confirmation modal
    const [personalToDelete, setPersonalToDelete] = useState(null); // Store personal details to delete
    const itemsPerPage = 5;
    const topRef = useRef();

    const deletePersonal = async (id) => {
        await axios
            .delete(`https://api.echfak.store/api/personals/${id}`)
            .then(({ data }) => {
                // console.log(data.message);
                setShowConfirmationModal(false); // Hide modal after deletion
                window.location.reload(); // Reload the page to reflect changes
            })
            .catch(({ response: { data } }) => {
                // console.log(data.message);
                setShowConfirmationModal(false); // Hide modal in case of error
            });
    };

    const confirmDelete = (id, phone) => {
        setPersonalToDelete({ id, phone }); // Store personal details to delete
        setShowConfirmationModal(true); // Show confirmation modal
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false); // Hide confirmation modal
    };

    // Filter personals based on search query
    const filteredPersonals = personals.filter((personal) =>
        personal.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
        personal.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        personal.title_head.toLowerCase().includes(searchQuery.toLowerCase()) ||
        personal.description_website.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Pagination logic
    const totalPages = Math.ceil(filteredPersonals.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedPersonals = filteredPersonals.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    return (
        <div className="custom-container" ref={topRef}>
            <div className="custom-row">
                <div className="custom-col-12 over">
                    <Link className="custom-btn custom-btn-primary custom-btn-float" to={"/personal/create"}>
                        Create
                    </Link>
                    <input
                        type="text"
                        className="custom-form-control custom-mb-2"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ maxWidth: "300px" }}
                    />
                    <div className="custom-col-12">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Title Head</th>
                                    <th>Logo</th>
                                    <th>Description Website</th>
                                    <th>Instagram</th>
                                    <th>Facebook</th>
                                    <th>TikTok</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedPersonals.length > 0 ? (
                                    paginatedPersonals.map((row, key) => (
                                        <tr key={key}>
                                            <td>{row.phone}</td>
                                            <td>{row.email}</td>
                                            <td>{row.title_head}</td>
                                            <td>
                                                <img
                                                    width="100px"
                                                    src={`https://api.echfak.store/storage/personal/logo/${row.logo}`}
                                                    alt="Logo"
                                                />
                                            </td>
                                            <td>{row.description_website}</td>
                                            <td>
                                                <a href={row.instagram} target="_blank" rel="noopener noreferrer">
                                                    Instagram
                                                </a>
                                            </td>
                                            <td>
                                                <a href={row.facebook} target="_blank" rel="noopener noreferrer">
                                                    Facebook
                                                </a>
                                            </td>
                                            <td>
                                                <a href={row.tiktok} target="_blank" rel="noopener noreferrer">
                                                    TikTok
                                                </a>
                                            </td>
                                            <td>
                                                <Link
                                                    className="custom-btn custom-btn-success"
                                                    to={`/personal/edit/${row.id}`}
                                                    style={{ marginRight: "10px" }}
                                                >
                                                    Edit
                                                </Link>
                                                <button
                                                    className="custom-btn custom-btn-danger"
                                                    onClick={() => confirmDelete(row.id, row.phone)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="9">No personals found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                className="custom-btn"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index}
                                    className={`custom-btn ${currentPage === index + 1 ? "custom-btn-primary" : "custom-btn-secondary"}`}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button
                                className="custom-btn"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirmation Modal */}
            {showConfirmationModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Are you sure?</h2>
                        <p>Do you want to delete the personal with phone "{personalToDelete.phone}"?</p>
                        <button
                            className="custom-btn custom-btn-danger"
                            onClick={() => deletePersonal(personalToDelete.id)}
                        >
                            OK
                        </button>
                        <button
                            className="custom-btn custom-btn-secondary"
                            onClick={handleCancelDelete}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
