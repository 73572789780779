import React from 'react'
import Home from '../components/MainPage/Home'
import FlashDeals from '../components/flashDeals/FlashDeals'
import { useOutletContext } from 'react-router-dom';
import Card from '../components/Card/Card';
import TopCate from '../components/top/TopCate';
import NewArrivals from '../components/newarrivals/NewArrivals';
import Discount from '../components/discount/Discount';
import Shop from '../components/shops/Shop';
import Annocument from '../components/annocument/Annocument';
import Wrapper from '../components/wrapper/Wrapper';
import Hero from '../components/Hero/Hero';
import Testimonials from '../components/testimonials/Testimonials';
import Navbar from '../components/filterProducts/Navbar';
import Products from '../components/productsList/Products';
import TopProducts from '../components/topProducts/TopProducts';
import Subscribe from '../components/subscribe/Subscribe';
import Banner from '../components/banner/Banner';

const Pages = ({ CartItem, shopItems }) => {
    const { productItems, addToCart } = useOutletContext();
  return (
    <div>
        <Hero />
        {/* <Home /> */}
        <TopProducts />
        <Card />
        <Banner />
        {/* <Navbar /> */}
        <Products />
        {/* <ParentComponent /> */}
        <Subscribe />
        <Annocument />
        {/* <Shop /> */}
        {/* <FlashDeals  Deals productItems={productItems} addToCart={addToCart} /> */}
        {/* <NewArrivals /> */}
        {/* <TopCate /> */}
        {/* <Discount /> */}
        <Wrapper />
        <Testimonials />
    </div>
  )
}

export default Pages