import React from "react"
import "./Header.css"
import Head from "./Head"
import Search from "./Search"
import Navbar from "./Navbar"

const Header = ({ CartItem , personals }) => {
  return (
    <>
      <Head personals={personals}/>
      {/* <Search CartItem={CartItem} /> */}
      <Navbar CartItem={CartItem} personals={personals}/>
    </>
  )
}

export default Header
