import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

export default function LoginAdmin(){
    const navigate = useNavigate();

    useEffect(()=>{
        if(localStorage.getItem("AUTHENTICATED")=== "Xejiz637jddjzJDJEI7637jdhdznuzdh837389jdbej837483837kdndejkdbdizen87474838397448kdndjeenejsj874477-@@/sshsjY173DKDL/jdhdkdhskzjzzzdziziznbdjiehdeihOJDJJEDJIEUIHEDHH9U38383IJDIJ338U3388YD38DY3DIH3UIHUDHYHD3YY3DY383IDHIDHIHD3F83FYF38YUFHUH3FUF33F3F3F3FUH3F83F83F83FUHFUIFIJ3IJID3DHIHI3DIDHIDHI3DH3ID3DY8Y3D8YD38D8D8I3I83D8ID383YHFKFKJFIJFRJIIIFIFRIFHHFHUFURHRRGF74"){
            navigate('/dashboard/mohammad/X537hdd636jdh/hdhdks8446hdhdjz/hhdeediJ9U393UND3I3HI/UDZDHIDEI98793J3K3HHJdhehuedjhhdeuedueguguideuedueueeddgueiziyzvzvcvycyeygyeggydedgeydgedygedygedgedyedgy/83474844647HHC847484748473838748474747DHDHHEHEHEHEHR84748483837373666262737664646BBCVFRRJFKFKD84747487585858577484847575484747HDGDTEYRKEDKSDKSTSTEEEG')
        }
    },[])



    const [patronEmail,setPatronEmail] = useState('mohammad@gmail.com')
    const [patronPassword, setPatronPassword] = useState('Xejiz637jddjzJDJEI7637jdhdznuzdh837389')
    const [patronAge, setPatronAge] = useState("1000")
    const [patronLogged, setPatronLogged] = useState("Xejiz637jddjzJDJEI7637jdhdznuzdh837389jdbej837483837kdndejkdbdizen87474838397448kdndjeenejsj874477-@@/sshsjY173DKDL/jdhdkdhskzjzzzdziziznbdjiehdeihOJDJJEDJIEUIHEDHH9U38383IJDIJ338U3388YD38DY3DIH3UIHUDHYHD3YY3DY383IDHIDHIHD3F83FYF38YUFHUH3FUF33F3F3F3FUH3F83F83F83FUHFUIFIJ3IJID3DHIHI3DIDHIDHI3DH3ID3DY8Y3D8YD38D8D8I3I83D8ID383YHFKFKJFIJFRJIIIFIFRIFHHFHUFURHRRGF74")


    const [email,setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [age, setAge] = useState('');


    const patronLogin = (e)=>{
        e.preventDefault();
        if(email === patronEmail && password === patronPassword && age === patronAge){
            localStorage.setItem("AUTHENTICATED", patronLogged);
            navigate("/dashboard/mohammad/X537hdd636jdh/hdhdks8446hdhdjz/hhdeediJ9U393UND3I3HI/UDZDHIDEI98793J3K3HHJdhehuedjhhdeuedueguguideuedueueeddgueiziyzvzvcvycyeygyeggydedgeydgedygedygedgedyedgy/83474844647HHC847484748473838748474747DHDHHEHEHEHEHR84748483837373666262737664646BBCVFRRJFKFKD84747487585858577484847575484747HDGDTEYRKEDKSDKSTSTEEEG")
        }
    }

    return (
        <div className="custom-container">
          <div className="custom-row custom-justify-content-center">
            <div className="custom-col-12">
              <div className="custom-card">
                <div className="custom-card-body">
                  <h3 className="custom-card-title">Login</h3>
                  <hr />
                  <div className="form-wrapper">
                    <form onSubmit={patronLogin}>
                      <div className="custom-form-group">
                        <label className="custom-form-label">Email</label>
                        <input
                          type="text"
                          className="custom-form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="custom-form-group">
                        <label className="custom-form-label">Password</label>
                        <input
                        type="password"
                          className="custom-form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="custom-form-group">
                        <label className="custom-form-label">Confirm Password</label>
                        <input
                          type="password"
                          className="custom-form-control"
                          value={age}
                          onChange={(e) => setAge(e.target.value)}
                        />
                      </div>
                      <div className="custom-form-group">
                        <button type="submit" className="custom-btn custom-btn-primary">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );




}