import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CreateBanner() {
    const navigate = useNavigate();

    const [imageOne, setImageOne] = useState("");
    const [imageTwo, setImageTwo] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false); // For success modal

    const changeHandlerOne = (e) => {
        setImageOne(e.target.files[0]);
        // console.log("Image One:", e.target.files[0]);
    };

    const changeHandlerTwo = (e) => {
        setImageTwo(e.target.files[0]);
        // console.log("Image Two:", e.target.files[0]);
    };

    const createBanner = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("image_one", imageOne);
        formData.append("image_two", imageTwo);

        // console.log(formData);
        await axios
            .post("https://api.echfak.store/api/banners", formData)
            .then(({ data }) => {
                // console.log(data.message);
                setShowSuccessModal(true); // Show success modal
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    // console.log(response.data.errors);
                } else {
                    // console.log(response.data.message);
                }
            });
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        navigate("/banners/list"); // Redirect after closing modal
    };

    return (
        <div className="custom-container">
            <div className="custom-row custom-justify-center">
                <div className="custom-col-12">
                    <div className="custom-card">
                        <div className="custom-card-body">
                            <h3 className="custom-card-title">Create Banner</h3>
                            <hr />
                            <div className="custom-form-wrapper">
                                <form onSubmit={createBanner}>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Image One (1080 x 1080)</label>
                                        <input
                                            type="file"
                                            className="custom-form-input"
                                            onChange={changeHandlerOne}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Image Two (1920 x 1080)</label>
                                        <input
                                            type="file"
                                            className="custom-form-input"
                                            onChange={changeHandlerTwo}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <button type="submit" className="custom-btn custom-btn-primary">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Congratulations!</h2>
                        <p>The banner has been successfully created.</p>
                        <button className="custom-btn custom-btn-success" onClick={handleCloseModal}>
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
