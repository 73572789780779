import React, { useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import "./Card.css";
import CategoriesProducts from "./CategoriesProduct";

const Card = () => {
  const { categories } = useOutletContext();
  const [categoryFilterId, setCategoryFilterId] = useState(null);
  const [categoryFilterTitle, setCategoryFilterTitle] = useState("");
  const productsRef = useRef(null); // Create a ref for the `CategoriesProducts` component

  const handleCategoryClick = (id, title) => {
    setCategoryFilterId(id);
    setCategoryFilterTitle(title);
    productsRef.current.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to the component
  };

  return (
    <div className="category_list">
      <div className="top-products-header">
        <p className="header-subtitle" data-aos="fade-up">
        اختر الفئة المفضلة لديك        
        </p>
        <h1 className="header-title" data-aos="fade-up">
        تسوق حسب الفئة        
        </h1>
        <p className="header-description" data-aos="fade-up">
        اكتشف جميع الفئات في متجرنا        
        </p>
      </div>

      <div className="main-container">
        {categories.map((row) => (
          <div
            key={row.id}
            className="category-card"
            onClick={() => handleCategoryClick(row.id, row.title)}
          >
            <div className="category-circle">
              <img
                src={`https://api.echfak.store/storage/category/image/${row.image}`}
                alt={row.title}
              />
            </div>
            <h2 className="category-title">{row.title}</h2>
          </div>
        ))}
      </div>

      {/* Conditionally render CategoriesProducts only when a category is selected */}
      <div ref={productsRef}>
        {categoryFilterId && (
          <CategoriesProducts
            categoryFilterId={categoryFilterId}
            categoryFilterTitle={categoryFilterTitle}
          />
        )}
      </div>
    </div>
  );
};

export default Card;
