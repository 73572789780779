import React, { useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";

const CategoriesProducts = ({ categoryFilterId, categoryFilterTitle }) => {
  const { products, addToCart } = useOutletContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const productsPerPage = 20;
  const topRef = useRef();

  // Filter products by category and search query
  const filteredProducts = useMemo(() => {
    return products.filter(
      (product) =>
        (!categoryFilterId || product.category_id === categoryFilterId) &&
        (product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.description.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, [products, categoryFilterId, searchQuery]);

  // Pagination logic
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const currentProducts = filteredProducts.slice(
    (currentPage - 1) * productsPerPage,
    currentPage * productsPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const getPagination = () => {
    const pages = [];
    
    if (totalPages <= 5) {
      // Show all pages if total pages are 5 or less
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Handle edge cases for the first and last pages
      if (currentPage > 3) {
        pages.push("...");
      }
      if (currentPage > 2) {
        pages.push(currentPage - 1);
      }
      pages.push(currentPage);
      if (currentPage < totalPages - 1) {
        pages.push(currentPage + 1);
      }
      if (currentPage < totalPages - 2) {
        pages.push("...");
      }
    }
  
    return pages;
  };
  
  

  return (
    <div className="top-products-container">
      <div ref={topRef}></div>

      {categoryFilterTitle && (
        <div dir="rtl" className="top-products-header">
          <h1 className="header-title" data-aos="fade-up" style={{ color: "#ed8900" }}>
            {categoryFilterTitle}
          </h1>
          <p className="header-description" data-aos="fade-up">
            المنتجات الخاصة بفئة {categoryFilterTitle}
          </p>
        </div>
      )}

      <div className="search-container">
        <i
          className="fa-solid fa-magnifying-glass search-icon"
          onClick={() => setIsSearchVisible(!isSearchVisible)}
        ></i>
        {isSearchVisible && (
          <input
            type="text"
            className="search-input"
            placeholder="Search products..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        )}
      </div>

      {/* Display a message if no products are found */}
      {filteredProducts.length === 0 ? (
        <div dir="rtl" className="no-products" data-aos="fade-up">
          <p>لا توجد منتجات في فئة {categoryFilterTitle} حاليا</p>
        </div>
      ) : (
        <>
          <div className="top-products-grid">
            {currentProducts.map((product) => (
              <div key={product.id} className="product-card" data-aos="zoom-in">
                <div className="discount-badge">-{product.discount}%</div>
                <div className="product-image">
                  <img
                    src={`https://api.echfak.store/storage/product/image/${product.image}`}
                    alt={product.title}
                    className="product-img"
                  />
                </div>
                <div dir="rtl" className="product-details">
                  <div className="product-stars">
                    {[...Array(product.stars)].map((_, i) => (
                      <i key={i} className="fa-solid fa-star star-icon"></i>
                    ))}
                  </div>
                  <h1 className="product-title">{product.title}</h1>
                  <p className="product-description">{product.description}</p>
                  <h1 dir="rtl" className="top-product-title" style={{color : "#fbbf24"}}>{product.price} درهم</h1>
                  <button className="product-button" onClick={() => addToCart(product)}>
                    أضف إلى السلة
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="pagination">
  <button
    className="pagination-button"
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  >
    <i className="fa-solid fa-chevron-left"></i>
  </button>
  {getPagination().map((page, index) =>
    typeof page === "number" ? (
      <button
        key={index}
        className={`pagination-page ${currentPage === page ? "active" : ""}`}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </button>
    ) : (
      <span key={index} className="pagination-ellipsis">
        ...
      </span>
    )
  )}
  <button
    className="pagination-button"
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  >
    <i className="fa-solid fa-chevron-right"></i>
  </button>
</div>


        </>
      )}
    </div>
  );
};

export default CategoriesProducts;
