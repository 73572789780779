import axios from "axios";
import React, { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import "./style.css";

const Cart = () => {
  const { productItems, addToCart, CartItem,incrementQty, decreaseQty, setCartItem, cities } = useOutletContext();

  const [selectedCityPrice, setSelectedCityPrice] = useState(0);
  const [selectedCity, setSelectedCity] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    const city = cities.find((city) => city.name === selectedCity);
    if (city) {
      setSelectedCity(selectedCity);
      setSelectedCityPrice(city.price);
    } else {
      setSelectedCity("");
      setSelectedCityPrice(0);
    }
  };

  const totalProductsPrice = CartItem.reduce(
    (price, item) => price + item.qty * item.price,
    0
  );

  const totalPrice = totalProductsPrice + selectedCityPrice;
  const isTotalLessThan150 = totalProductsPrice < 150;

  const removeItem = (itemId) => {
    const updatedCart = CartItem.filter((item) => item.id !== itemId);
    setCartItem(updatedCart);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (CartItem.length === 0 || !selectedCity || !totalPrice) {
      alert("Please ensure all fields are completed!");
      return;
    }

    const orderData = {
      customer_name: e.target.name.value,
      phone: e.target.phone.value,
      address: e.target.address.value,
      city: selectedCity,
      total_price: totalPrice,
      cart: CartItem.map((item) => ({
        id: item.id,
        qty: item.qty,
        price: item.price,
        product_title: item.title,
      })),
    };

    setLoading(true);

    try {
      const response = await axios.post("https://api.echfak.store/api/orders", orderData);
      // console.log(response.data.message);
      setShowSuccessModal(true);
      setCartItem([]);
    } catch (error) {
      console.error("Order submission failed:", error);
      alert("Failed to place the order. Please try again!");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <section className="cart-items">
      <Link to={"/"}>
              <div className="top-right-link">
            <i className="fa-solid fa-arrow-left"></i> الرجوع الى التسوق
        </div>
      </Link>
      <div className="container d_flex flexing">
        {/* Top-right link */}
        <div className="cart-details">
          {CartItem.length === 0 && (
            <h1 className="no-items product">لم يتم إضافة أي عناصر إلى سلة التسوق</h1>
          )}

          {CartItem.map((item) => {
            const productQty = item.price * item.qty;

            return (
              <div className="cart-list product d_flex" key={item.id}>
                <div className="img">
                  <img
                    src={`https://api.echfak.store/storage/product/image/${item.image}`}
                    alt=""
                  />
                </div>
                <div className="cart-details">
                  <h3>{item.title}</h3>
                  <h4>
                    {item.qty}
                    <span>{productQty}.Dh</span>
                  </h4>
                </div>
                <div className="cart-items-function">
                  <div className="removeCart">
                    <button
                      className="removeCart icon_remove"
                      onClick={() => removeItem(item.id)}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                  <div className="cartControl d_flex">
                    <button className="incCart" onClick={() => incrementQty(item.id)}>
                      <i className="fa-solid fa-plus"></i>
                    </button>
                    <button className="desCart" onClick={() => decreaseQty(item)}>
                      <i className="fa-solid fa-minus"></i>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div dir="rtl" className="cart-total product">
          <h2>اكمال عملية الشراء</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">الاسم الكامل</label>
            <input type="text" id="name" name="name" placeholder="أدخل اسمك" required />

            <label htmlFor="phone">رقم الهاتف</label>
            <input id="phone" name="phone" placeholder="أدخل رقم هاتفك" required />

            <label htmlFor="city">المدينة</label>
            <select id="city" name="city" onChange={handleCityChange} required>
              <option value="">اختر مدينتك</option>
              {cities.map((item, key) => (
                <option key={key} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>

            <label htmlFor="address">العنوان</label>
            <input
              type="text"
              id="address"
              name="address"
              placeholder="أدخل عنوانك"
              required
            />

            <div className="d_flex">
              <h4>سعر المنتجات :</h4>
              <h3>{totalProductsPrice} درهم</h3>
            </div>
            <div className="d_flex">
              <h4>التوصيل :</h4>
              <h4>{selectedCityPrice} درهم</h4>
            </div>
            <div className="d_flex">
              <h4>السعر الإجمالي :</h4>
              <h3>{totalPrice} درهم</h3>
            </div>
            <button
              type="submit"
              disabled={isTotalLessThan150 || loading}
              className={`buy-button ${isTotalLessThan150 || loading ? "disabled" : ""}`}
            >
              شراء
            </button>
          </form>

          {isTotalLessThan150 && (
            <h2>يجب أن يكون مجموع سعر المنتجات أكثر من 150 درهم لإكمال عملية الشراء</h2>
          )}
        </div>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div dir="rtl" className="modal-overlay">
          <div className="modal">
            <h2>تهانينا!</h2>
            <p>ستتوصلون بمنتج مجاني مع طلبيتكم</p>
            <button className="custom-btn custom-btn-success" onClick={handleCloseModal}>
              حسنا
            </button>
          </div>
        </div>
      )}

      {/* Loading Spinner */}
      {loading && (
        <div className="loading-screen">
          <div className="spinner"></div>
          <h2>جاري معالجة طلبك... الرجاء الانتظار</h2>
        </div>
      )}
    </section>
  );
};

export default Cart;
