import React from "react";
import { useOutletContext } from "react-router-dom";
const Annocument = () => {
  const { banners } = useOutletContext();
  // console.log("banners : ",banners)
  const mystyle = {
    width: "30%",
    height: "340px",
  }
  const mystyle1 = {
    width: "68%",
    height: "340px",
  }
  return (
    <>
      <section className='annocument background'>
        <div className='container d_flex'>
          <div className='img' style={mystyle}>
            <img src={`https://api.echfak.store/storage/banner/image/${banners[0]?.image_one}`} width='100%' height='100%' />
          </div>
          <div className='img' style={mystyle1}>
            <img src={`https://api.echfak.store/storage/banner/image/${banners[0]?.image_two}`} width='100%' height='100%' />
          </div>
        </div>
      </section>
    </>
  )
}

export default Annocument
