import axios from "axios";
import React, { useRef, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import "./style.css";

export default function PromotionList() {
    const { promotions } = useOutletContext();
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [promotionToDelete, setPromotionToDelete] = useState(null);
    const itemsPerPage = 5;
    const topRef = useRef();

    const deletePromotion = async (id) => {
        await axios.delete(`https://api.echfak.store/api/promotions/${id}`)
            .then(({ data }) => {
                // console.log(data.message);
                setShowConfirmationModal(false);
                window.location.reload();
            })
            .catch(({ response: { data } }) => {
                // console.log(data.message);
                setShowConfirmationModal(false);
            });
    };

    const confirmDelete = (id, title) => {
        setPromotionToDelete({ id, title });
        setShowConfirmationModal(true);
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
    };

    // Filter promotions based on search query
    const filteredPromotions = promotions.filter(promotion => 
        promotion.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        promotion.description.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Pagination logic
    const totalPages = Math.ceil(filteredPromotions.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedPromotions = filteredPromotions.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    return (
        <div className="custom-container" ref={topRef}>
            <div className="custom-row">
                <div className="custom-col-12 over">
                    <Link className="custom-btn custom-btn-primary custom-btn-float" to={"/promotion/create"}>
                        Create
                    </Link>
                    <input
                        type="text"
                        className="custom-form-control custom-mb-2"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ maxWidth: "300px" }}
                    />
                    <div className="custom-col-12">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Image</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedPromotions.length > 0 ? (
                                    paginatedPromotions.map((row, key) => (
                                        <tr key={key}>
                                            <td style={{ maxWidth: "120px" }}>{row.title}</td>
                                            <td style={{ maxWidth: "120px" }}>{row.description}</td>
                                            <td>
                                                <img
                                                    width="100px"
                                                    src={`https://api.echfak.store/storage/promotion/image/${row.image}`}
                                                    alt="Promotion"
                                                />
                                            </td>
                                            <td>
                                                <Link
                                                    className="custom-btn custom-btn-success"
                                                    to={`/promotion/edit/${row.id}`}
                                                    style={{ marginRight: "10px" }}
                                                >
                                                    Edit
                                                </Link>
                                                <button
                                                    className="custom-btn custom-btn-danger"
                                                    onClick={() => confirmDelete(row.id, row.title)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No promotions found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                className="custom-btn"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index}
                                    className={`custom-btn ${currentPage === index + 1 ? "custom-btn-primary" : "custom-btn-secondary"}`}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button
                                className="custom-btn"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirmation Modal */}
            {showConfirmationModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Are you sure?</h2>
                        <p>Do you want to delete the promotion "{promotionToDelete.title}"?</p>
                        <button
                            className="custom-btn custom-btn-danger"
                            onClick={() => deletePromotion(promotionToDelete.id)}
                        >
                            OK
                        </button>
                        <button
                            className="custom-btn custom-btn-secondary"
                            onClick={handleCancelDelete}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
