import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";

export default function EditProduct() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [price, setPrice] = useState("");
  const [size, setSize] = useState("");
  const [colors, setColors] = useState("");
  const [categories, setCategories] = useState([]); // To store categories
  const [categoryId, setCategoryId] = useState(""); // To store selected category
  const [isValid, setIsValid] = useState("yes");  // For isValid field
  const [discount, setDiscount] = useState(0);    // For discount field
  const [stars, setStars] = useState(0);          // For stars field
  const [showSuccessModal, setShowSuccessModal] = useState(false); // Success modal state

  useEffect(() => {
    fetchProduct();
    fetchCategories();
  }, []);

  const fetchProduct = async () => {
    await axios
      .get(`https://api.echfak.store/api/products/${id}`)
      .then(({ data }) => {
        const { title, description, price, size, colors, category_id, isValid, discount, stars } = data.product;
        setTitle(title);
        setDescription(description);
        setPrice(price);
        setSize(size);
        setColors(colors);
        setCategoryId(category_id); // Set the category_id
        setIsValid(isValid);  // Set isValid
        setDiscount(discount); // Set discount
        setStars(stars);      // Set stars
      })
      .catch(({ response: { data } }) => {
        // console.log(data.message);
      });
  };

  const fetchCategories = async () => {
    await axios
      .get("https://api.echfak.store/api/categories")
      .then(({ data }) => {
        // console.log("Fetched categories:", data);
        setCategories(data);
      })
      .catch((error) => {
        // console.error("Error fetching categories:", error);
      });
  };

  const changeHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const updateProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("_method", "PATCH");
    formData.append("title", title);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("size", size);
    formData.append("colors", colors);
    formData.append("category_id", categoryId); // Append category_id to form data
    formData.append("isValid", isValid);       // Append isValid
    formData.append("discount", discount);     // Append discount
    formData.append("stars", stars);           // Append stars
    if (image !== null) {
      formData.append("image", image);
    }

    await axios
      .post(`https://api.echfak.store/api/products/${id}`, formData)
      .then(({ data }) => {
        // console.log(data.message);
        setShowSuccessModal(true); // Show success modal after update
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          // console.log(response.data.errors);
        } else {
          // console.log(response.data.message);
        }
      });
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate("/product/list"); // Redirect after closing modal
  };

  return (
    <div className="custom-container">
      <div className="custom-row custom-justify-content-center">
        <div className="custom-col-12">
          <div className="custom-card">
            <div className="custom-card-body">
              <h3 className="custom-card-title">Edit Product</h3>
              <hr />
              <div className="form-wrapper">
                <form onSubmit={updateProduct}>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Title</label>
                    <input
                      type="text"
                      className="custom-form-control"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Description</label>
                    <textarea
                      className="custom-form-control"
                      rows="3"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Image</label>
                    <input type="file" className="custom-form-control" onChange={changeHandler} />
                  </div>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Price</label>
                    <input
                      type="text"
                      className="custom-form-control"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Size</label>
                    <input
                      type="text"
                      className="custom-form-control"
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                    />
                  </div>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Colors</label>
                    <input
                      type="text"
                      className="custom-form-control"
                      value={colors}
                      onChange={(e) => setColors(e.target.value)}
                    />
                  </div>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Category</label>
                    <select
                      className="custom-form-control"
                      value={categoryId}
                      onChange={(e) => setCategoryId(e.target.value)}
                    >
                      <option value="">Select a category</option>
                      {categories.length > 0 ? (
                        categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.title}
                          </option>
                        ))
                      ) : (
                        <option value="">No categories available</option>
                      )}
                    </select>
                  </div>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Top Products</label>
                    <select
                      className="custom-form-control"
                      value={isValid}
                      onChange={(e) => setIsValid(e.target.value)}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Discount (%)</label>
                    <input
                      type="number"
                      className="custom-form-control"
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </div>
                  <div className="custom-form-group">
                    <label className="custom-form-label">Stars</label>
                    <input
                      type="number"
                      className="custom-form-control"
                      value={stars}
                      onChange={(e) => setStars(e.target.value)}
                      max={5}
                      min={0}
                    />
                  </div>
                  <div className="custom-form-group">
                    <button type="submit" className="custom-btn custom-btn-primary">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Success!</h2>
            <p>The product has been successfully updated.</p>
            <button className="custom-btn custom-btn-success" onClick={handleCloseModal}>
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
