import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './NavbarDashboard.css';

const NavbarDashboard = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const loggoutAdmin = () =>{
    (localStorage.setItem("AUTHENTICATED" , "false"))
    navigate('/')
  }

  return (
    <div>
      <div className="unique-navbar">
        <div className="unique-navbar-container">
          <button className="unique-navbar-toggler" onClick={toggleMenu} aria-label="Toggle navigation">
            ☰
          </button>
          <div className={`unique-navbar-menu ${menuOpen ? 'open' : ''}`}>
            <ul className="unique-navbar-nav">
              <Link to={"/dashboard/mohammad/X537hdd636jdh/hdhdks8446hdhdjz/hhdeediJ9U393UND3I3HI/UDZDHIDEI98793J3K3HHJdhehuedjhhdeuedueguguideuedueueeddgueiziyzvzvcvycyeygyeggydedgeydgedygedygedgedyedgy/83474844647HHC847484748473838748474747DHDHHEHEHEHEHR84748483837373666262737664646BBCVFRRJFKFKD84747487585858577484847575484747HDGDTEYRKEDKSDKSTSTEEEG"}>
              <li className="unique-nav-item">
                <span className="unique-nav-link active">Dashboard</span>
              </li>
              </Link>
              <Link to={"/order/list"}>
              <li className="unique-nav-item">
                <span className="unique-nav-link active">Orders</span>
              </li>
              </Link>
              <Link to={"/product/list"}>
              <li className="unique-nav-item">
                <span className="unique-nav-link active">Products</span>
              </li>
              </Link>
              <Link to={"/category/list"}>
              <li className="unique-nav-item">
                <span className="unique-nav-link active">Category</span>
              </li>
              </Link>
              <Link to={"/ville/list"}>
              <li className="unique-nav-item">
                <span className="unique-nav-link active">Villes</span>
              </li>
              </Link>
              <Link to={"/personal/list"}>
              <li className="unique-nav-item">
                <span className="unique-nav-link active">Personal</span>
              </li>
              </Link>
              <Link to={"/sliders/list"}>
              <li className="unique-nav-item">
                <span className="unique-nav-link active">Sliders</span>
              </li>
              </Link>
              <Link to={"/promotions/list"}>
              <li className="unique-nav-item">
                <span className="unique-nav-link active">Promotions</span>
              </li>
              </Link>
              <Link to={"/subscibers/list"}>
              <li className="unique-nav-item">
                <span className="unique-nav-link active">Subscribers</span>
              </li>
              </Link>
              <Link to={"/banners/list"}>
              <li className="unique-nav-item">
                <span className="unique-nav-link active">Banners</span>
              </li>
              </Link>
              <Link to={"/comments/list"}>
              <li className="unique-nav-item">
                <span className="unique-nav-link active">Comments</span>
              </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      <div className='buttons_header'>
      <Link to={"/"}>
        <div className='' style={{margin : "10px"}}>
          <button className='custom-btn custom-btn-primary custom-mb-2'>Back to website</button>
        </div>
      </Link>
      <Link to={"/"}>
        <div className='' style={{margin : "10px"}} onClick={loggoutAdmin}>
          <button className='custom-btn custom-btn-loggout custom-mb-2'>Logout</button>
        </div>
      </Link>
      </div>
    </div>
  );
};

export default NavbarDashboard;
