import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CreatePersonal() {
    const navigate = useNavigate();

    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [titleHead, setTitleHead] = useState("");
    const [logo, setLogo] = useState("");
    const [descriptionWebsite, setDescriptionWebsite] = useState("");
    const [instagram, setInstagram] = useState("");
    const [facebook, setFacebook] = useState("");
    const [tiktok, setTiktok] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false); // For success modal

    const changeHandler = (e) => {
        setLogo(e.target.files[0]);
        // console.log(e.target.files[0]);
    };

    const createPersonal = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("title_head", titleHead);
        formData.append("logo", logo);
        formData.append("description_website", descriptionWebsite);
        formData.append("instagram", instagram);
        formData.append("facebook", facebook);
        formData.append("tiktok", tiktok);

        // console.log(formData);
        await axios.post("https://api.echfak.store/api/personals", formData)
            .then(({ data }) => {
                // console.log(data.message);
                setShowSuccessModal(true); // Show success modal
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    // console.log(response.data.errors);
                } else {
                    // console.log(response.data.message);
                }
            });
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        navigate("/personal/list"); // Redirect after closing modal
    };

    return (
        <div className="custom-container">
            <div className="custom-row custom-justify-center">
                <div className="custom-col-12">
                    <div className="custom-card">
                        <div className="custom-card-body">
                            <h3 className="custom-card-title">Create Personal</h3>
                            <hr />
                            <div className="custom-form-wrapper">
                                <form onSubmit={createPersonal}>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Phone</label>
                                        <input
                                            type="text"
                                            className="custom-form-input"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Email</label>
                                        <input
                                            type="email"
                                            className="custom-form-input"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Title Head</label>
                                        <input
                                            type="text"
                                            className="custom-form-input"
                                            value={titleHead}
                                            onChange={(e) => setTitleHead(e.target.value)}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Logo (98px x 44px)</label>
                                        <input
                                            type="file"
                                            className="custom-form-input"
                                            onChange={changeHandler}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Description Website</label>
                                        <textarea
                                            className="custom-form-textarea"
                                            rows="3"
                                            value={descriptionWebsite}
                                            onChange={(e) => setDescriptionWebsite(e.target.value)}
                                        ></textarea>
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Instagram</label>
                                        <input
                                            type="url"
                                            className="custom-form-input"
                                            value={instagram}
                                            onChange={(e) => setInstagram(e.target.value)}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Facebook</label>
                                        <input
                                            type="url"
                                            className="custom-form-input"
                                            value={facebook}
                                            onChange={(e) => setFacebook(e.target.value)}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">TikTok</label>
                                        <input
                                            type="url"
                                            className="custom-form-input"
                                            value={tiktok}
                                            onChange={(e) => setTiktok(e.target.value)}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <button type="submit" className="custom-btn custom-btn-primary">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Congratulations!</h2>
                        <p>The personal profile has been successfully created.</p>
                        <button className="custom-btn custom-btn-success" onClick={handleCloseModal}>
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
