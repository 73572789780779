import React, { useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import "./TopProducts.css";

const TopProducts = ({ handleOrderPopup }) => {
  const { products, addToCart } = useOutletContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const productsPerPage = 8;

  // Reference to the top of the component
  const topRef = useRef();

  // Filtered products based on search and isValid
  const filteredProducts = products.filter(
    (product) =>
      (product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.description.toLowerCase().includes(searchQuery.toLowerCase())) &&
      product.isValid === "yes" // Only include products with isValid = "yes"
  );

  // Calculate pagination values
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const startIndex = (currentPage - 1) * productsPerPage;
  const currentProducts = filteredProducts.slice(
    startIndex,
    startIndex + productsPerPage
  );

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);

      // Scroll to the top of the component
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handle search query change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);

    // Reset pagination to the first page
    setCurrentPage(1);
  };

  const getPagination = () => {
    const pagination = [];

    if (currentPage > 2) {
      pagination.push("...");
    }

    if (currentPage > 1) {
      pagination.push(currentPage - 1);
    }

    pagination.push(currentPage);

    if (currentPage < totalPages) {
      pagination.push(currentPage + 1);
    }

    if (currentPage < totalPages - 1) {
      pagination.push("...");
    }

    return pagination;
  };

  return (
    <div className="top-products-container">
      {/* Top reference for scrolling */}
      <div ref={topRef}></div>

      <div className="top-products-header">
        <p className="header-subtitle" data-aos="fade-up">
          المنتجات الأعلى تقييمًا
        </p>
        <h1 className="header-title" data-aos="fade-up">
          الاكثر مبيعا
        </h1>
        <p className="header-description" data-aos="fade-up">
          تعرف على أفضل المنتجات الأكثر مبيعا في متجرنا
        </p>
      </div>

      {/* Search Icon and Input */}
      <div className="search-container">
        <i
          className="fa-solid fa-magnifying-glass search-icon"
          onClick={() => setIsSearchVisible(!isSearchVisible)}
        ></i>
        {isSearchVisible && (
          <input
            type="text"
            className="search-input"
            placeholder="Search products..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        )}
      </div>

      {/* Product Grid or No Item Card */}
      {filteredProducts.length > 0 ? (
        <div className="top-products-grid">
          {currentProducts.map((data, key) => (
            <div key={key} className="top-product-card" data-aos="zoom-in">
              <div className="discount-badge">-{data.discount}%</div>
              <div className="top-product-image">
                <img
                  src={`https://api.echfak.store/storage/product/image/${data.image}`}
                  alt={data.title}
                  className="top-product-img"
                />
              </div>
              <div className="product-details top-product-details">
                <div className="top-product-stars">
                  {[...Array(data.stars)].map((_, i) => (
                    <i key={i} className="fa-solid fa-star top-star-icon"></i>
                  ))}
                </div>
                <h1 className="top-product-title">{data.title}</h1>
                <p className="top-product-description">{data.description}</p>
                <h1
                  dir="rtl"
                  className="top-product-title"
                  style={{ color: "#fbbf24" }}
                >
                  {data.price} درهم
                </h1>
                <button
                  className="top-product-button"
                  onClick={() => addToCart(data)}
                >
                  أضف إلى السلة
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-item-card">
          <p>No items</p>
        </div>
      )}

      {/* Pagination Section */}
      {filteredProducts.length > 0 && (
        <div className="pagination">
          <button
            className="pagination-button"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          {getPagination().map((page, index) =>
            typeof page === "number" ? (
              <button
                key={index}
                className={`pagination-page ${currentPage === page ? "active" : ""}`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            ) : (
              <span key={index} className="pagination-ellipsis">
                ...
              </span>
            )
          )}
          <button
            className="pagination-button"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default TopProducts;
