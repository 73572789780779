import React from "react";
import "./Head.css";

const Head = ({personals}) => {
  return (
    <>
      <section className="head">
        <div className="container">
          <div className="marquee">
            <div className="content">
              <div className="left row">
                <i className="fa fa-phone"></i>
                <label>{personals[0]?.phone}</label>
                <i className="fa fa-envelope"></i>
                <label>{personals[0]?.email}</label>
                  <i className="fa-solid fa-truck-fast"></i>
                <label>{personals[0]?.title_head}</label>
              </div>
              <div className="right row RText">
                {/* <div className="fast_truck">
                  <i className="fa-solid fa-truck-fast"></i>
                <label>{personals[0]?.title_head}</label>
                </div> */}
                {/* <label>Need Help?</label> */}
                {/* <span>🇲🇦</span>
                <label>AR</label>
                <span>🏳️‍⚧️</span>
                <label>FR</label> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
