import React from "react";
import { useOutletContext } from "react-router-dom";
import Slider from "react-slick";
import Image3 from "./assets/hero/sale.png";
import Image2 from "./assets/hero/shopping.png";
import Image1 from "./assets/hero/women.png";
import "./Hero.css"; // Import the CSS file

const ImageList = [
  {
    id: 1,
    img: Image1,
    title: "Upto 50% off on all Men's Wear",
    description:
      "lorem His Life will forever be Changed dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    id: 2,
    img: Image2,
    title: "30% off on all Women's Wear",
    description:
      "Who's there lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    id: 3,
    img: Image3,
    title: "70% off on all Products Sale",
    description:
      "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const Hero = ({ handleOrderPopup }) => {
  const { sliders,products } = useOutletContext();
  // console.log("sliders :",sliders)
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "ease-in-out",
    pauseOnHover: false,
    pauseOnFocus: true,
  };

  return (
    <div className="hero-container">
      {/* Background Pattern */}
      <div className="hero-pattern"></div>
      {/* Hero Section */}
      <div className="hero-content">
        <Slider {...settings}>
          {sliders.map((data,key) => (
            <div key={key}>
              <div className="hero-grid">
                {/* Text Content Section */}
                <div className="hero-text" dir="rtl">
                  <h1 className="hero-title" data-aos="zoom-out" data-aos-duration="500" data-aos-once="true">
                    {data.title}
                  </h1>
                  <p className="hero-description" data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
                    {data.description}
                  </p>
                  {/* <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
                    <button onClick={handleOrderPopup} className="hero-button">
                      Order Now
                    </button>
                  </div> */}
                </div>
                {/* Image Section */}
                <div className="hero-image" data-aos="zoom-in" data-aos-once="true">
                  <img src={`https://api.echfak.store/storage/slider/image/${data.image}`} alt={data.title} className="hero-img" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Hero;
