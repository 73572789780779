import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";

export default function EditCategory() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false); // For success modal

    useEffect(() => {
        fetchCategory();
    }, []);

    const fetchCategory = async () => {
        await axios
            .get(`https://api.echfak.store/api/categories/${id}`)
            .then(({ data }) => {
                const { title, description } = data.category;
                setTitle(title);
                setDescription(description);
            })
            .catch(({ response: { data } }) => {
                // console.log(data.message);
            });
    };

    const changeHandler = (e) => {
        setImage(e.target.files[0]);
    };

    const updateCategory = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("_method", "PATCH");
        formData.append("title", title);
        formData.append("description", description);
        if (image !== null) {
            formData.append("image", image);
        }

        await axios
            .post(`https://api.echfak.store/api/categories/${id}`, formData)
            .then(({ data }) => {
                // console.log(data.message);
                setShowSuccessModal(true); // Show success modal
            })
            .catch(({ response }) => {
                if (response.status === 422) {
                    // console.log(response.data.errors);
                } else {
                    // console.log(response.data.message);
                }
            });
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        navigate("/category/list"); // Redirect after closing modal
    };

    return (
        <div className="custom-container">
            <div className="custom-row custom-justify-center">
                <div className="custom-col-12">
                    <div className="custom-card">
                        <div className="custom-card-body">
                            <h3 className="custom-card-title">Edit Category</h3>
                            <hr />
                            <div className="custom-form-wrapper">
                                <form onSubmit={updateCategory}>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Title</label>
                                        <input
                                            type="text"
                                            className="custom-form-input"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Description</label>
                                        <textarea
                                            className="custom-form-textarea"
                                            rows="3"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        ></textarea>
                                    </div>
                                    <div className="custom-form-group">
                                        <label className="custom-form-label">Image</label>
                                        <input
                                            type="file"
                                            className="custom-form-input"
                                            onChange={changeHandler}
                                        />
                                    </div>
                                    <div className="custom-form-group">
                                        <button type="submit" className="custom-btn custom-btn-primary">
                                            Update
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Success!</h2>
                        <p>The category has been successfully updated.</p>
                        <button className="custom-btn custom-btn-success" onClick={handleCloseModal}>
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
