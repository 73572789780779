import React from "react";
import { useOutletContext } from "react-router-dom";
import Slider from "react-slick";
import "./Testimonials.css"; // Import the CSS file for styling

const TestimonialData = [
  {
    id: 1,
    name: "Victor",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque reiciendis inventore iste ratione ex alias quis magni at optio",
    img: "https://picsum.photos/101/101",
  },
  {
    id: 2,
    name: "Satya Nadella",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque reiciendis inventore iste ratione ex alias quis magni at optio",
    img: "https://picsum.photos/102/102",
  },
  {
    id: 3,
    name: "Virat Kohli",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque reiciendis inventore iste ratione ex alias quis magni at optio",
    img: "https://picsum.photos/104/104",
  },
  {
    id: 4,
    name: "Sachin Tendulkar",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque reiciendis inventore iste ratione ex alias quis magni at optio",
    img: "https://picsum.photos/103/103",
  },
];

const Testimonials = () => {
  const { comments } = useOutletContext();
  // console.log("comments : ",comments)
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 10000,
        settings: { slidesToShow: 3, slidesToScroll: 1 },
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
      {
        breakpoint: 640,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  return (
    <div className="testimonials-section">
        <div className="container">
          <div className="header-section">
            <p className="subheading">ماذا يقول عملاؤنا</p>
            <h1 className="heading">شهادات</h1>
            <p className="description">
            شهادات حول منتجاتنا وخدماتنا
            </p>
          </div>

          <div className="slider-container">
            <Slider {...settings}>
              {comments.map((data,key) => (
                <div className="testimonial-card" key={key}>
                  <div className="testimonial-content">
                    <img src={`https://api.echfak.store/storage/comments/images/${data.image}`} alt={data.title} className="testimonial-image" />
                    <p className="testimonial-text">{data.description}</p>
                    <h2 className="testimonial-name">{data.title}</h2>
                    <span className="quote-icon">,,</span>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
    </div>
  );
};

export default Testimonials;
