import axios from "axios";
import React, { useRef, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import "./style.css";

export default function BannerList() {
    const { banners } = useOutletContext();
    // console.log("banners jdjd : ", banners);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [bannerToDelete, setBannerToDelete] = useState(null);
    const itemsPerPage = 5;
    const topRef = useRef();

    const deleteBanner = async (id) => {
        await axios
            .delete(`https://api.echfak.store/api/banners/${id}`)
            .then(({ data }) => {
                // console.log(data.message);
                setShowConfirmationModal(false);
                window.location.reload();
            })
            .catch(({ response: { data } }) => {
                // console.log(data.message);
                setShowConfirmationModal(false);
            });
    };

    const confirmDelete = (id) => {
        setBannerToDelete(id);
        setShowConfirmationModal(true);
    };

    const handleCancelDelete = () => {
        setShowConfirmationModal(false);
    };

    const filteredBanners = banners.filter((banner) =>
        banner.image_one.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredBanners.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedBanners = filteredBanners.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    // Generate pagination buttons dynamically
    const getPagination = () => {
        const buttons = [];
        const visiblePages = 2; // Number of pages before/after current

        // Add the first page
        if (currentPage > 1 + visiblePages) {
            buttons.push(1);
            if (currentPage > 2 + visiblePages) buttons.push("...");
        }

        // Add current, previous, and next pages
        for (let i = Math.max(1, currentPage - visiblePages); i <= Math.min(totalPages, currentPage + visiblePages); i++) {
            buttons.push(i);
        }

        // Add the last page
        if (currentPage < totalPages - visiblePages) {
            if (currentPage < totalPages - visiblePages - 1) buttons.push("...");
            buttons.push(totalPages);
        }

        return buttons;
    };

    return (
        <div className="custom-container" ref={topRef}>
            <div className="custom-row">
                <div className="custom-col-12 over">
                    <Link
                        className="custom-btn custom-btn-primary custom-btn-float"
                        to={"/banner/create"}
                    >
                        Create
                    </Link>
                    <input
                        type="text"
                        className="custom-form-control custom-mb-2"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ maxWidth: "300px" }}
                    />
                    <div className="custom-col-12">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Image One</th>
                                    <th>Image Two</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedBanners.length > 0 ? (
                                    paginatedBanners.map((row, key) => (
                                        <tr key={key}>
                                            <td>
                                                <img
                                                    width="100px"
                                                    src={`https://api.echfak.store/storage/banner/image/${row.image_one}`}
                                                    alt="Banner One"
                                                />
                                            </td>
                                            <td>
                                                <img
                                                    width="100px"
                                                    src={`https://api.echfak.store/storage/banner/image/${row.image_two}`}
                                                    alt="Banner Two"
                                                />
                                            </td>
                                            <td>
                                                <Link
                                                    className="custom-btn custom-btn-success"
                                                    to={`/banner/edit/${row.id}`}
                                                    style={{ marginRight: "10px" }}
                                                >
                                                    Edit
                                                </Link>
                                                <button
                                                    className="custom-btn custom-btn-danger"
                                                    onClick={() => confirmDelete(row.id)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3">No banners found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <button
                                className="custom-btn"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            {getPagination().map((page, index) =>
                                typeof page === "number" ? (
                                    <button
                                        key={index}
                                        className={`custom-btn ${
                                            currentPage === page
                                                ? "custom-btn-primary"
                                                : "custom-btn-secondary"
                                        }`}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </button>
                                ) : (
                                    <span key={index} className="pagination-ellipsis">
                                        {page}
                                    </span>
                                )
                            )}
                            <button
                                className="custom-btn"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {showConfirmationModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Are you sure?</h2>
                        <p>Do you want to delete this banner?</p>
                        <button
                            className="custom-btn custom-btn-danger"
                            onClick={() => deleteBanner(bannerToDelete)}
                        >
                            OK
                        </button>
                        <button
                            className="custom-btn custom-btn-secondary"
                            onClick={handleCancelDelete}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
