import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import NavbarDashboard from '../components/dashboard/navbar/NavbarDashboard';
import './LayoutDashboard.css';
import API_BASE_URL from '../config';
// import API_BASE_URL from '../config';

const LayoutDashboard = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        if(localStorage.getItem("AUTHENTICATED") !== "Xejiz637jddjzJDJEI7637jdhdznuzdh837389jdbej837483837kdndejkdbdizen87474838397448kdndjeenejsj874477-@@/sshsjY173DKDL/jdhdkdhskzjzzzdziziznbdjiehdeihOJDJJEDJIEUIHEDHH9U38383IJDIJ338U3388YD38DY3DIH3UIHUDHYHD3YY3DY383IDHIDHIHD3F83FYF38YUFHUH3FUF33F3F3F3FUH3F83F83F83FUHFUIFIJ3IJID3DHIHI3DIDHIDHI3DH3ID3DY8Y3D8YD38D8D8I3I83D8ID383YHFKFKJFIJFRJIIIFIFRIFHHFHUFURHRRGF74"){
            navigate('/')
        }
    },[])

    const [loading, setLoading] = useState(true); // Loading state
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [personals, setPersonals] = useState([]);
    const [villes, setVilles] = useState([]);
    const [orders, setOrders] = useState([]);
    const [sliders, setSliders] = useState([]);
    const [subscribers, setSubscribers] = useState([]);
    const [promotions, setPromotions] = useState([]);
    const [banners, setBanners] = useState([]);
    const [comments, setComments] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const [productsRes, categoriesRes, personalsRes, villesRes,ordersRes,slidersRes,promotionsRes,subscribersRes,bannersRes,commentsRes] = await Promise.all([
            axios.get(`https://api.echfak.store/api/products`),
            axios.get(`https://api.echfak.store/api/categories`),
            axios.get(`https://api.echfak.store/api/personals`),
            axios.get(`https://api.echfak.store/api/villes`),
            axios.get(`https://api.echfak.store/api/orders`),
            axios.get(`https://api.echfak.store/api/sliders`),
            axios.get(`https://api.echfak.store/api/promotions`),
            axios.get(`https://api.echfak.store/api/subscribers`),
            axios.get(`https://api.echfak.store/api/banners`),
            axios.get(`https://api.echfak.store/api/comments`),
          ]);
  
          setProducts(productsRes.data);
          setCategories(categoriesRes.data);
          setPersonals(personalsRes.data);
          setVilles(villesRes.data);
          setOrders(ordersRes.data)
          setSliders(slidersRes.data);
          setPromotions(promotionsRes.data);
          setSubscribers(subscribersRes.data);
          setBanners(bannersRes.data);
          setComments(commentsRes.data);
  
          setLoading(false); // Set loading to false after data is fetched
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false); // Stop loading even if there's an error
        }
      };
  
      fetchData();
    }, []);

  return (
        <div>
        {loading && (
          <div className="loading-screen">
            <div className="spinner"></div>
            <h2>جاري التحميل... الرجاء الإنتظار</h2>
          </div>
        )}
        <div className={loading ? "content blur" : "content"}>
        <NavbarDashboard />
          <Outlet
            context={{
              products,
              categories,
              personals,
              villes,
              orders,
              sliders,
              promotions,
              subscribers,
              banners,
              comments,
            }}
          />
        </div>
      </div>
  )
}

export default LayoutDashboard