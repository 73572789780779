import { useRef } from "react";
import { useOutletContext } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
// function Navbar() {
	// const [filterCategory, setFilterCategory] = useState("");
	const { categories } = useOutletContext();
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle("responsive_nav");
	};


	return (
		<header className="header_filter">
            <div className="for_container">
			{/* <h3>LOGO</h3> */}
			<nav className="nav_" ref={navRef}>
				{categories.map((row,key)=>{
					return(
						<div>
							<div className="d_flex_" onClick={showNavbar}>
								<img src={`https://api.echfak.store/storage/category/image/${row.image }`} alt="" className="img_categories"/>
							<span href="/">{row.title}</span>
							</div>
						</div>
					)
				})}
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<i className="fa-solid fa-xmark icon_filter_product"></i>
				</button>
			</nav>
            <div onClick={showNavbar}>
			<button className="nav-btn">
				<i className="fa-solid fa-bars icon_filter_product"></i>
			</button>
        <h2 className="h2_filter">Filter</h2>
            </div>
            </div>
		</header>
	);
}

export default Navbar;
